import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Page } from '@shared';
import { NotFound, OpenInApp } from '@src/components/pages/404/components';

const UNIVERSAL_LINK_PARAM = 'universal';

function Custom404() {
  const intl = useIntl();
  const [universalLink, setUniversalLink] = useState<string>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get(UNIVERSAL_LINK_PARAM)) {
      setUniversalLink(window.location.href);
    }
  }, []);

  if (universalLink) {
    return (
      <Page
        title={intl.formatMessage({
          defaultMessage: 'Open in app',
          id: '8XhRcN',
        })}
      >
        <OpenInApp url={universalLink} />
      </Page>
    );
  }

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Not found',
        id: 'TThIOM',
      })}
    >
      <NotFound />
    </Page>
  );
}

export default Custom404;
