import { useIntl } from 'react-intl';

import * as S from './NotFound.styles';

import { Spacer } from '@ui';
import { Footer, Header } from '@shared';

const worldAstronaut = require('./assets/world-astronaut@3x.webp');

function NotFound() {
  const intl = useIntl();
  return (
    <S.PageContainer>
      <Header />
      <S.Container>
        <S.Image src={worldAstronaut.default.src} />
        <div>
          <S.Text color="light" variant="h1" strong>
            {intl.formatMessage({
              defaultMessage: '404 Not found',
              id: 'VX4Q5g',
            })}
          </S.Text>
          <S.Text color="light">
            {intl.formatMessage({
              defaultMessage:
                "The page you're trying to access does not exist.",
              id: 'WABFv/',
            })}
          </S.Text>
        </div>
      </S.Container>
      <Footer />
      <Spacer size={11} />
    </S.PageContainer>
  );
}

export default NotFound;
