import { useIntl } from 'react-intl';

import * as S from './OpenInApp.styles';

import { Spacer, Text } from '@ui';
import { Footer, Header } from '@shared';

import QRCode from '../QRCode';
const astronaut = require('./assets/astronaut@3x.webp');
const atm = require('./assets/atm@3x.webp');

interface OpenInAppProps {
  url: string;
}

function OpenInApp({ url }: OpenInAppProps) {
  const intl = useIntl();
  return (
    <S.PageContainer>
      <Header />
      <S.Container>
        <S.Content>
          <QRCode text={url} size={40} />
          <Spacer size={2} />
          <Text color="light" variant="h3" strong align="center">
            {intl.formatMessage({
              defaultMessage: 'Scan QR with your phone',
              id: '+cW1A3',
            })}
          </Text>
          <S.Subtitle color="light" colorVariant="tertiary" align="center">
            {intl.formatMessage({
              defaultMessage:
                'Grab your phone and scan the QR code to continue to Sammy app.',
              id: 'KXCHCP',
            })}
          </S.Subtitle>
          <S.TopRightImage>
            <S.Astronaut src={astronaut.default.src} />
          </S.TopRightImage>
          <S.BottomLeftImage>
            <S.ATM src={atm.default.src} />
          </S.BottomLeftImage>
        </S.Content>
      </S.Container>
      <Footer />
      <Spacer size={11} />
    </S.PageContainer>
  );
}

export default OpenInApp;
