import { useEffect, useState } from 'react';
import qrcode from 'qrcode';
import { Spacing } from 'styled-components';
import * as Sentry from '@sentry/react';

import * as S from './QRCode.styles';

interface QRCodeProps {
  text: string;
  size: Spacing;
}

function QRCode({ text, size }: QRCodeProps) {
  const [qrData, setQRData] = useState<string>();

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const base64 = await qrcode.toDataURL(text);
        setQRData(base64);
      } catch (err) {
        Sentry.captureException(err);
      }
    };

    generateQRCode();
  }, [text]);

  return <S.Image src={qrData} size={size} />;
}

export default QRCode;
