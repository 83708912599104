import styled, { Spacing } from 'styled-components';

interface ImageProps {
  size: Spacing;
}

export const Image = styled.img<ImageProps>`
  width: ${({ theme, size }) => theme.spacing[size]}px;
  height: ${({ theme, size }) => theme.spacing[size]}px;
  border-radius: ${({ theme }) => theme.spacing[8]}px;
  padding: ${({ theme }) => theme.spacing[4]}px;
`;
