import styled from 'styled-components';

import { Text as UIText } from '@ui';

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[5]}px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    /* max-width: ${({ theme }) => theme.spacing[128]}px; */
  }
`;

export const Image = styled.img`
  width: ${({ theme }) => theme.spacing[80]}px;
  max-width: 70vw;
`;

export const Text = styled(UIText)`
  @media (max-width: 576px) {
    text-align: center;
  }
`;
