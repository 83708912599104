import styled from 'styled-components';

import { Text } from '@src/components/ui';

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PageContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    max-width: ${({ theme }) => theme.spacing[128]}px;
  }
`;

export const TopRightImage = styled.div`
  position: absolute;
  top: -60%;
  right: 0;
  z-index: -1;
`;

export const BottomLeftImage = styled.div`
  position: absolute;
  bottom: -70%;
  left: 0;
  z-index: -1;
`;

export const Astronaut = styled.img`
  max-height: ${({ theme }) => theme.spacing[40]}px;
  max-width: ${({ theme }) => theme.spacing[40]}px;
`;

export const ATM = styled.img`
  max-height: ${({ theme }) => theme.spacing[40]}px;
  max-width: ${({ theme }) => theme.spacing[40]}px;
`;

export const Subtitle = styled(Text)`
  max-width: ${({ theme }) => theme.spacing[112]}px;
  padding: 0 ${({ theme }) => theme.spacing[4]}px;
`;
